import { Link } from "gatsby"
import React from "react"
import "./adoption-fee.css"

const AdoptionFee = ({children,note})=>{

    return <section className="adption-fee">
        <div className="content">
            {children}
                 <div className="adopt-fee">
                <h3>Adoption Fees</h3>
                <p className='italic mb-5'>Effective Jan 1, 2025:</p>
                 <p><span className="price">$425</span> <span className="text">PUPPY ADOPTION</span><span className="text2">(&lt; 6 months old)</span></p>
                <p><span className="price">$350</span> <span className="text">ADULT ADOPTION</span><span className="text2">(6 months - 7+ years old)</span></p>
                <p><span className="price">$250</span> <span className="text">SENIOR ADOPTION</span><span className="text2">(8+ years old)</span></p>
              <Link className="btn-primary" to="/adoption-process/">Apply for Adoption</Link>
            </div>
            <p>{note}</p>

        </div>
        
    </section>
}

export default AdoptionFee